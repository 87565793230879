<template>
  <div>
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'AddUser'">
    </breadcrumb-base>
    <transition name="fade">
      <div class="content-center">
        <div class="vx-col md:w-4/5 w-full mb-base c-center">
          <vx-card :title="userId ? $t('EditStaff') : $t('AddNewStaff')">
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full">

                <div class="vx-col w-full mt-3" v-if="userId == null">
                  <vs-input
                    v-validate="'required'"
                    validate-on="change"
                    :name="$t('FirstName')"
                    class="w-full" :label-placeholder="$t('FirstName') + '*'"
                    v-model="firstName"/>
                  <span class="text-danger text-sm">{{ errors.first($t('FirstName')) }}</span>
                </div>

                <div class="vx-col w-full mt-6" v-if="userId == null">
                  <vs-input
                    v-validate="'required'"
                    validate-on="change"
                    :name="$t('LastName')"
                    class="w-full" :label-placeholder="$t('LastName') + '*'"
                    v-model="lastName"/>
                  <span class="text-danger text-sm">{{ errors.first($t('LastName')) }}</span>
                </div>

                <div class="vx-col w-full mt-6">
                  <vs-input
                    :disabled="userId != null"
                    v-validate="'required|min:3'"
                    validate-on="change"
                    :name="$t('UserName')"
                    class="w-full" :label-placeholder="$t('UserName') + '*'"
                    @change="checkUserDebounceTime"
                    v-model="username"/>
                  <span class="text-danger text-sm">{{ errors.first($t('UserName')) }}</span>
                  <span class="text-danger text-sm" v-if="!usernameAvailable">{{ $t('UsernameNotAvailable') }}</span>
                </div>

                <div class="mt-6">
                  <label for="mobile" class="inline-block mb-1 font-medium">{{ $t('MobileNumber') }} <span class="text-danger">*</span></label>
                  <input
                    placeholder="+491234567890"
                    required=""
                    type="text"
                    v-model="mobile"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                    id="mobile"
                    name="mobile"
                  />
                </div>

                <div class="vx-col w-full mt-6" v-if="userId == null">
                  <vs-input
                    v-validate="'required'"
                    validate-on="change"
                    :name="$t('Password')"
                    type="password"
                    @input="passCheck"
                    class="w-full" :label-placeholder="$t('Password') + '*'"
                    v-model="password"/>
                  <span class="text-danger text-sm">{{ errors.first($t('Password')) }}</span>
                </div>

                <div class="vx-col px-3 w-full" v-if="userId == null">
                  <p class="text-sm pass-check-item" :class="[passStrengthValue.caps ? 'text-success' : 'text-gray']">
                    {{ $t('UppercaseLetter') }}</p>
                  <p class="text-sm pass-check-item" :class="[passStrengthValue.small ? 'text-success' : 'text-gray']">
                    {{ $t('LowercaseLetter') }}</p>
                  <p class="text-sm pass-check-item"
                     :class="[passStrengthValue.numbers ? 'text-success' : 'text-gray']">{{ $t('Number') }}</p>
                  <p class="text-sm pass-check-item"
                     :class="[passStrengthValue.special ? 'text-success' : 'text-gray']">{{ $t('OneSymbol') }}</p>
                  <p class="text-sm pass-check-item" :class="[passStrengthValue.length ? 'text-success' : 'text-gray']">
                    {{ $t('EightCharacters') }}</p>
                </div>

                <div class="vx-col w-full mt-6" v-if="userId == null">
                  <vs-input
                    v-validate="'required'"
                    validate-on="change"
                    :name="$t('ConfirmPassword')"
                    type="password"
                    class="w-full" :label-placeholder="$t('ConfirmPassword') + '*'"
                    v-model="confirmPassword"/>
                  <span class="text-danger text-sm">{{ errors.first($t('ConfirmPassword')) }}</span>
                </div>

                <vs-checkbox class="vx-col mt-6" v-model="userActive">Active</vs-checkbox>


                <div class="mt-6" v-if="userId != null">
                  <vs-button @click="showChangePassword = true" color="danger" type="filled">{{ $t('ChangePassword') }}
                  </vs-button>
                </div>
              </div>

              <div class="vx-col md:w-1/2 w-full">
                <div class="vx-col mb-2 mt-6 px-12">
                  <div class="vx-col">
                    <h4 style="padding: 0 3px;">{{ $t('Privileges') }} :</h4>
                    <div class="mt-6"></div>
                  </div>
                  <div class="">
                    <vs-checkbox class="vx-col mt-3" v-model="hasCashCards">{{ $t('Products') }} ({{ $t('CashCards')  }}, {{ $t('GiftCards') }})</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasDirectRecharge">{{
                        $t('DirectRecharge')
                      }}
                    </vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasInternationalTopUp">{{
                        $t('InternationalTopUp')
                      }}
                    </vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasFlixBus">{{ $t('FlixBus') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasTransaction">{{
                        $t('EnableSales')
                      }}
                    </vs-checkbox>
<!--                    <div style="font-size: 12px; color: #9d9d9d; margin-left: 32px;">-->
<!--                      <label>{{ $t('SellingPermissionDescription') }}</label>-->
<!--                    </div>-->
                    <vs-checkbox class="vx-col mt-3" v-model="hasReports">{{ $t('Reports') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasReclamation">{{ $t('Reclamation') }}</vs-checkbox>
                    <vs-checkbox class="vx-col mt-3" v-model="hasInvoice">{{ $t('Invoices') }}</vs-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row mt-6">
              <vs-button class="ml-4" :disabled="userId == null ? !validateForm : false"
                         @click="userId == null ? addUserAndForward() : updateUserAndForward()">
                {{ userId == null ? $t('SaveUser') : $t('UpdateUser') }}
              </vs-button>
            </div>
            <div class="m-4"></div>

          </vx-card>
        </div>
      </div>
    </transition>

    <vx-modal v-if="showChangePassword" @close="showChangePassword = false" component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body">
        <div class="p-6 flex flex-centered">
          <vx-col>
            <h3><strong>Change Password</strong></h3>
            <div class="w-full py-4 vs-row">
              <vs-input
                autofocus class="w-full py-"
                size="large" type="password" v-validate="'required'"
                validate-on="change"
                @input="changePassCheck"
                :label-placeholder="$t('Password')" v-model="newPassword"/>

              <div class="vs-col">
                <p class="text-sm pass-check-item" :class="[passStrengthValue.caps ? 'text-success' : 'text-gray']">
                  {{ $t('UppercaseLetter') }}</p>
                <p class="text-sm pass-check-item" :class="[passStrengthValue.small ? 'text-success' : 'text-gray']">
                  {{ $t('LowercaseLetter') }}</p>
                <p class="text-sm pass-check-item" :class="[passStrengthValue.numbers ? 'text-success' : 'text-gray']">
                  {{ $t('Number') }}</p>
                <p class="text-sm pass-check-item" :class="[passStrengthValue.special ? 'text-success' : 'text-gray']">
                  {{ $t('OneSymbol') }}</p>
                <p class="text-sm pass-check-item" :class="[passStrengthValue.length ? 'text-success' : 'text-gray']">
                  {{ $t('EightCharacters') }}</p>
              </div>

            </div>
            <div class="vs-row">
              <vs-input class="w-full py-4" size="large" type="password" v-validate="'required'" validate-on="change"
                        :label-placeholder="$t('ConfirmPassword')" v-model="newConfirmPassword"/>

            </div>
            <div class="vs-row flex justify-center mt-3">
              <vs-button class="ml-2 mt-3" color="primary" @click="changePassword()">{{
                  $t('ChangePassword')
                }}
              </vs-button>
              <vs-button class="ml-4 mt-3" color="danger" @click="showChangePassword = false">{{
                  $t('Close')
                }}
              </vs-button>
            </div>
          </vx-col>

        </div>
      </template>
      <template slot="footer"></template>
    </vx-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import VxModal from '@/layouts/components/custom/VxModal'

export default {
  name: 'AddUser',
  components: {
    'v-select': vSelect,
    VxModal,
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.firstName !== '' && this.lastName !== ''
        && this.username !== '' && this.password !== '' && this.confirmPassword !== ''
        && this.usernameAvailable
    },
  },
  data() {
    return {
      newPassword: '',
      newConfirmPassword: '',
      showChangePassword: false,
      usernameAvailable: true,
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      mobile: '',
      confirmPassword: '',
      hasCashCards: true,
      hasTransaction: true,
      hasDirectRecharge: true,
      hasInternationalTopUp: true,
      hasFlixBus: true,
      hasReports: true,
      hasReclamation: true,
      hasInvoice: true,
      internalPermissionId: null,
      userActive: true,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Users', i18n: 'Users', url: '/seller/users/view'},
        {title: 'View User', i18n: 'ViewUser', active: true},
      ],
      passStrengthValue: {
        'caps': false,
        'length': false,
        'special': false,
        'numbers': false,
        'small': false,
      },
    }
  },
  props: {
    userId: {
      type: Number | String,
      default: null,
    },
  },
  methods: {

    changePassword() {
      if (!(this.passStrengthValue.caps && this.passStrengthValue.length
        && this.passStrengthValue.special && this.passStrengthValue.numbers
        && this.passStrengthValue.small)) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password is not valid',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if (this.newPassword !== this.newConfirmPassword) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password not matched',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      this.$vs.loading()
      return this.$store.dispatch('customer/changeCustomerPassword',
        {customerId: this.userId, password: this.newPassword, username: this.username})
        .then((data) => {
          this.$vs.loading.close()
          if (data.message_type == 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Success',
              text: 'Password updated',
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success',
            })
            this.newConfirmPassword = ''
            this.newPassword = ''
            this.showChangePassword = false
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    changePassCheck(value) {
      let caps = false
      let numbers = false
      let small = false
      let special = false
      for (let index = 0; index < this.newPassword.length; index++) {
        const char = this.newPassword.charCodeAt(index)
        if (char >= 65 && char <= 90) {
          caps = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if (char >= 97 && char <= 122) {
          small = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if ([33, 35, 36, 37, 38, 64, 42, 63, "33", "35", "36", "37", "38", "64", "42", "63"].includes(char)) {
          special = true
        }
      }
      this.passStrengthValue.caps = caps
      this.passStrengthValue.numbers = numbers
      this.passStrengthValue.small = small
      this.passStrengthValue.special = special
      if (this.newPassword.length < 8) {
        this.passStrengthValue.length = false
      } else {
        this.passStrengthValue.length = true
      }
      const obj = {...this.passStrengthValue}
      this.passStrengthValue = {}
      this.passStrengthValue = {...obj}
    },
    passCheck(value) {
      let caps = false
      let numbers = false
      let small = false
      let special = false

      for (let index = 0; index < this.password.length; index++) {
        const char = this.password.charCodeAt(index)
        if (char >= 65 && char <= 90) {
          caps = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if (char >= 97 && char <= 122) {
          small = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
          // ! - 33, # - 35, $ - 36, % - 37, & - 38, @ - 64, * - 42, ? - 63
        } else if ([33, 35, 36, 37, 38, 64, 42, 63, "33", "35", "36", "37", "38", "64", "42", "63"].includes(char)) {
          special = true
        }
      }
      this.passStrengthValue.caps = caps
      this.passStrengthValue.numbers = numbers
      this.passStrengthValue.small = small
      this.passStrengthValue.special = special
      if (this.password.length < 8) {
        this.passStrengthValue.length = false
      } else {
        this.passStrengthValue.length = true
      }
      const obj = {...this.passStrengthValue}
      this.passStrengthValue = {}
      this.passStrengthValue = {...obj}
    },
    checkUserDebounceTime() {
      clearTimeout(this.interval)
      this.interval = setTimeout(() => {
        this.checkUsername()
      }, 500)
    },
    checkUsername() {
      this.usernameAvailable = true
      this.$store.dispatch('user/checkCustomerUsername', this.username)
        .then((data) => {
          console.log(data)
          if (data.message_type == 1) {
            this.usernameAvailable = true
          } else {
            this.usernameAvailable = false
          }
        })
        .catch((err) => {
          if (err.response.data.message_type == 0) {
            this.usernameAvailable = false
          }
        })
    },
    updateUserAndForward() {
      const payload = {
        has_product: this.hasCashCards === true ? 1 : 0,
        has_transaction: this.hasTransaction === true ? 1 : 0,
        has_direct_recharge: this.hasDirectRecharge === true ? 1 : 0,
        has_dt_one: this.hasInternationalTopUp === true ? 1 : 0,
        has_flixbus: this.hasFlixBus === true ? 1 : 0,
        is_active: this.userActive === true ? 1 : 0,
        has_reports: this.hasReports === true ? 1 : 0,
        has_reclamation: this.hasReclamation == true ? 1 : 0,
        has_invoice: this.hasInvoice == true ? 1 : 0,
        mobile: this.mobile,
        internal_permission_id: this.internalPermissionId,
      }
      payload.user_id = this.userId
      this.$vs.loading()

      this.$store.dispatch('user/updateSellerUser', payload).then((data) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.$router.push({name: 'seller-users-view'})
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
    addUserAndForward() {
      let passValid = true
      Object.keys(this.passStrengthValue).forEach((k) => {
        if (this.passStrengthValue[k] == false) {
          passValid = false
        }
      })

      if (!passValid) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password not valid',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if (this.password !== this.confirmPassword) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password not matched',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      const payload = {
        first_name: this.firstName,
        last_name: this.lastName,
        username: this.username,
        password: this.password,

        has_product: this.hasCashCards === true ? 1 : 0,
        mobile: this.mobile,

        has_transaction: this.hasTransaction === true ? 1 : 0,
        has_direct_recharge: this.hasDirectRecharge === true ? 1 : 0,
        has_dt_one: this.hasInternationalTopUp === true ? 1 : 0,
        has_flixbus: this.hasFlixBus === true ? 1 : 0,
        has_reports: this.hasReports === true ? 1 : 0,
        is_active: this.userActive === true ? 1 : 0,
        has_reclamation: this.hasReclamation === true ? 1 : 0,
        has_invoice: this.hasInvoice === true ? 1 : 0,
      }
      this.$vs.loading()

      this.$store.dispatch('user/addNewSellerUser', payload).then((data) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.$router.push({name: 'seller-users-view'})
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
  },
  mounted() {
    if (this.userId !== null) {
      this.$vs.loading()
      return this.$store.dispatch('user/fetchSingleUser', this.userId)
        .then((data) => {
          this.username = data.user_name
          this.hasFlixBus = data.has_flixbus === 1
          this.hasCashCards = data.has_product === 1
          this.hasInternationalTopUp = data.has_dt_one === 1
          this.hasDirectRecharge = data.has_direct_recharge === 1
          this.hasTransaction = data.has_transaction === 1
          this.hasReports = data.has_reports === 1
          this.userActive = data.is_active
          this.mobile = data.mobile

          this.hasReclamation = data.has_reclamation === 1
          this.hasInvoice = data.has_invoice === 1

          this.internalPermissionId = data.internal_permission_id


          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    }
  },
}
</script>

<style scoped>
.c-center {
  margin: auto;
}

.upload-btn-wrapper input[type=file] {
  position: absolute;
  margin: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.p-image {
  width: 90%;
  object-fit: cover;
  max-height: 200px;
  margin: 1rem;
}

.p-image-denomination {
  width: 50%;
}
</style>
